

import Storage from "@/util/Storage";
import {addAreaList, updateAreaList,} from "@/Api/ShopAdmin";
import {Toast} from "vant";


export function addShopArea(upData:any={}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");

    if ( !upData.storeId ) {
        Toast("请选择店铺")
        return Promise.reject()
    }
    if ( !upData.school ) {
        Toast("请选择配送区域")
        return Promise.reject()
    }
    upData.userId = userId
    Toast.loading({ duration:0,message:"添加中~~~" })
    return (
        Promise.resolve(addAreaList({ userId,token },upData).then(res=>{
            Toast.clear()
            if (res.message.code == "200")Toast("添加成功")
            return res.data
        }))
    )
}

export function updateShopArea(upData:any={},oldData:any={}) {
    const userId = Storage.GetData_?.("userId");
    const token = Storage.GetData_?.("token");
    const keys = Object.keys(upData);
    let num = 0
    keys.forEach(item=>{
        if ( upData[item] != null && ((upData[item]+'').length < 1) || upData[item] != oldData[item] ) num += 1;
    })
    if (num<1){
        Toast("请修改内容")
        return Promise.reject()
    }
    upData.id = oldData.id
    // 默认修改旧店铺 配送区域
    if (oldData.storeId)upData.storeId = oldData.storeId;

    Toast.loading({ duration:0,message:"添加中~~~" })
    return (
        Promise.resolve(updateAreaList({ userId,token },upData).then(res=>{
            Toast.clear()
            if (res.message.code == "200")Toast("修改成功")
            return res.data
        }))
    )
}
